.upgrade-card {
  // top: 366px;
  // left: 1093px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 100px;
  overflow: hidden;
  //background: transparent linear-gradient(112deg, #40bcf9 0%, #1e87f0 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  //transform: matrix(0, 1, -1, 0, 0, 0);
  opacity: 1;
  // &:before, &:after {
  //   position: absolute;
  // }

  // &:before {
  //   top: calc(0% - var(--r) - .25*var(--r));
  //   left: calc(100% - var(--a, 0)*var(--r) + var(--b, 0)*.25*var(--r));
  //   padding: var(--r);
  //   outline: dashed 1px #000;
  //   border-radius: 50%;
  //   box-shadow: 0 0 0 1px #95a;
  //   background:
  //     radial-gradient(#b53 2px, transparent 3px),
  //     repeating-linear-gradient(45deg, #f90, #f90 2px, transparent 0, transparent .5em);
  //   content: ''
  // }

  // &:after {
  //   left: calc(-.75*var(--r));
  //   top: calc(100% + .5*var(--r));
  //   padding: .5em;
  //   border-radius: .5em;
  //   background: #333;
  //   color: #ddd;
  //   font: 1em consolas, monaco, monospace;
  //   white-space: nowrap;
  //   content: 'left: 100%'
  // }
  // &::after {
  //   content: "";
  //   position:absolute;
  //   margin-left:200px;
  //   width:50px;
  //   height:20px;
  //   transform:rotate(45deg);
  //   background-color:#000; /* to see where it is */
  //   box-shadow: 0 0 0 250px #522d5b;

  // }
  img {
    width: 89px;
    height: 74px;
    opacity: 1;
    margin-top: 13px;
    margin-left: 9px;
    // stroke-dasharray: 300;
    // /* stroke-dashoffset: 300; */
    // transition: all .8s ease-in-out;
  }

  .inside-text {
    font-family: tajawal;
    font-size: 12px;
    color: $pure_white;
    opacity: 1;
    margin-top: 14px;
    letter-spacing: 0px;
    margin-right: 8px;
  }
  .upgrade-group {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 8px;
  }
  .upgrade-button {
    width: 100px;
    height: 25px;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    margin-bottom: 8px;
    margin-right: 19px;
    text-align: center;
    text-transform: none;
    color: #2ba7df;
    font-size: 12px;
    font-weight: bold;
    transition: all 300ms ease;
    &:hover {
      border: 1px solid white;
      color: white;
    }
  }
  // &::before {
  //     position: absolute;
  //     bottom: -114px;
  //     left: -146px;
  //     width: 230px;
  //     height: 100px;
  //     transform: matrix(0.91, -0.42, 0.42, 0.91, 0, 0);
  //     opacity: 0.2;
  //     border-radius: 50%;
  //     z-index: -9;
  //     background: transparent linear-gradient(112deg, #40BCF9 0%, #1E87F0 100%) 0% 0% no-repeat padding-box;
  // }
  // &::after {
  //   content: '';
  //   position: absolute;
  //   margin-top: 0;
  //   margin-right: 0;
  //   border-color: transparent;
  //   border-style: solid;
  // }
}
