.historique-transaction {
  width: 100%;
  border: 1px solid $white1;
  border-collapse: separate;
  border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;

  th {
    font-size: 13px;
    font-weight: 400;
    padding: 0;
    text-align: center;
    color: $grey1;
    letter-spacing: 0px;
    background: $white3 no-repeat padding-box;
    border-bottom: 1px solid $blue1;
    border-top: none;
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }
}

table {
  border: 1px solid $white2;
  border-collapse: separate;
  border-left: 0;
  border-spacing: 0px;
  overflow: hidden;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

th,
td {
  padding: 1px 4px 6px 4px;
  vertical-align: top;
  border-left: 1px solid $white2;

  border-top-color: $pure_white;
  border-top-style: none;
  border-top-width: 1px;
  border-right-color: $white1;
  border-right-width: 1px;
  border-bottom-color: $pure_white;
  border-bottom-style: none;
  border-bottom-width: 1px;
  border-left-color: $white1;

  text-align: center;
}

thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
  border-radius: 0 0 0 0;
}
thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 0;
}
