.my-progress-widget {
  & > .MuiCardContent-root {
    display: flex;
    flex-direction: column;
  }
}
.rtl{
  .my-progress-widget {
    & > .MuiCardContent-root {
      direction: rtl;    
    }
  }
}