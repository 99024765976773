.offers-card {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-right: 10px !important;

  @media screen and (max-width: 1199px) {
    padding-left: 0px;
  }

  .offer-card {
    display: flex;
    justify-content: space-around;
    min-height: 1000px;
    height: auto;
    max-width: 350px;
    margin-bottom: 20px;
    border-radius: 10px !important;
    margin-right: 10px;
    box-shadow: 0px 3px 10px #2ba7df29 !important;


    .card {
      //width: 500px !important;
      min-width: 330px !important;

      .show-more-card {
        display: flex;
        justify-content: center;
        width: 100%;
        cursor: pointer;
        align-items: center;

        .show-more-icon {
          height: 40px;
          width: 30px;
          cursor: pointer;

          @keyframes down {
            0% {
              transform: translate(0);
            }

            20% {
              transform: translateY(15px);
            }

            40% {
              transform: translate(0);
            }
          }
        }

        .show-more-text {
          color: $grey;
          font-size: medium;
          font-family: Tajawal !important;
        }

        .hide-icon {
          height: 40px;
          width: 30px;
        }

        &:hover {
          .show-more-icon {
            -webkit-animation: down 1.5s infinite;
          }
        }
      }
    }

    .offer-header {
      //width: 250px;
      height: 250px;
      position: relative;
      z-index: 1;

      > svg {
        position: absolute;
        width: 100%;
      }

      .offer-bookmark {
        position: absolute;
        width: 80px;
        height: 90px;
        text-shadow: 2px 2px 0px #00000026;
        filter: drop-shadow(3px 1px 0px rgba(0, 0, 0, 0.15));
        right: 0;

        //left: 0;
        @media screen and (max-width: 1250px) {
          width: 70px;
          height: 80px;
        }

        @media screen and (max-width: 1000px) {
          width: 60px;
          height: 80px;
        }
      }

      .offer-header-container {
        position: relative;
        height: 100%;
        width: 100%;
        z-index: 0;
        background-size: cover;
        background-size: cover;
        fill: #4a3c98;
        opacity: 0.99;

        .offer-details {
          display: flex;
          flex-direction: column;
          align-items: center;

          .offer-name {
            color: white !important;
            margin: 20px 0px 0px 0px;
            font-size: 20px;
          }

          .offer-price {
            text-shadow: 0px 2px 4px #00000029;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .base-offer-price {
              font-weight: bold;
              font-size: 23px;
              text-decoration: line-through;

              @media screen and (max-width: 1351px) {
                font-size: 16px;
              }
            }

            .final-offer-price {
              font-weight: bold;
              font-size: 35px;

              @media screen and (max-width: 1351px) {
                font-size: 25px;
              }

              @include mqs(1700px, 1351px) {
                font-size: 30px;
              }
            }
          }
        }
      }

      // @media screen and (max-width: 1250px) {
      //   height: 150px;
      // }
      // @include mqs(1590px, 1250px) {
      //   height: 200px;
      //   // max-width: 256px;
      // }
    }

    .down {
      margin: -70px auto 20px !important;

      @media screen and (max-width: 1150px) {
        margin: -50px auto 20px !important;
      }
    }

    .offer-periods {
      display: flex;
      border-radius: 5px;
      margin: -60px auto 20px;
      justify-content: center;
      width: 80% !important;
      position: relative;
      z-index: 2;
      height: 70px;

      @media screen and (max-width: 1150px) {
        margin: -70px auto 20px;
      }

      .period {
        width: 60% !important;
        color: $grey1;
        border: black 2px solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 13px;
        text-align: center;
        padding: 5px;
        height: 70px !important;

        @media screen and (max-width: 1150px) {
          padding: 5px 2px;
        }

        @media screen and (min-width: 1380px) {
          padding: 5px 5px;
        }

        // @media all and (max-width:1376px )and (min-width:1146px ) {
        //     padding: 5px 18px;
        //   }
        //   @media screen and ( min-width:1381px ) {
        //     padding: 5px 28px;
        //     }
      }

      .last-period {
        color: $grey1;
        border: black 2px solid;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        padding: 2px;
        border-right: 20px !important;
        cursor: pointer;
        height: 70px;
        text-align: center;

        //   @media screen and (min-width: 1607px) {
        //  padding: 5px 15px;
        //  text-align: center;
        // }
        // @media screen and (min-width: 1607px) {
        //     padding: 5px 20px;
        //     text-align: center;
        //   }
      }

      .selected-period * div {
        cursor: pointer !important;
        height: 70px;
      }

      .period:first-child {
        border-radius: 5px 0px 0px 5px;
        height: 70px;
        text-align: center;
      }

      .period:last-child {
        border-radius: 0px 5px 5px 0px;
        height: 70px;
        text-align: center;
      }

      .period:nth-child(2) {
        border-left: 0px;
        height: 70px;
        text-align: center;
      }

      .period:nth-child(3) {
        border-left: 0px;
        height: 70px;
        text-align: center;
      }

      .period:nth-child(4) {
        border-left: 0px;
        height: 70px;
        text-align: center;
      }

      .selected-period {
        color: white;
        font-weight: bold;
        height: 70px;
        text-align: center;
      }
    }

    .ShowMoreButton {
      width: 94%;
      margin: 20px 10px;
      height: 40px;
      display: flex;
      justify-content: center;
      background-color: #000000;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      cursor: pointer !important;

      .ShowMoreButtonText {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 10px;
      }
    }

    .offer-submit-buy {
      margin: 20px 10px;
      height: 40px;
      display: flex;
      justify-content: center;
      background-color: black;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      cursor: pointer;

      .offerButtonText {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .downButton {
      bottom: 0 !important;
    }

    .offer-submit-buy-disable {
      margin: 20px 10px;
      height: 40px;
      display: flex;
      justify-content: space-around;
      background-color: black;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      pointer-events: none;

      .offerButtonText {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .offer-extend {
      border: 2px $blue solid !important;
    }

    .offer-list-details {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;

      .offer-list-icon {
        margin-right: 15px;
        color: $green;
        opacity: 0.87;
        font-size: 32px;
        filter: hue-rotate(0deg) !important;
      }

      .offer-list-text {
        color: $darker_blue;
        font-weight: bold;
        min-height: 550px !important;
        overflow: hidden;
      }

      .offer-list-text-hidden {
        opacity: 50%;
        margin-left: 48px;
      }
    }

    .offer-subject-list-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      margin: 5px 15px;
      border-radius: 10px;
      width: 100%;
      min-height: 90px;
      opacity: 0.8;
      background-color: #0000000f;

      .box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        margin: 0;
      }

      .title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      .label {
        font-size: 14px;
        color: #000000;
        font-weight: bold;
        font-family: Tajawal;
        margin-left: 10px;
        opacity: 1;
        text-align: center;
        text-transform: uppercase;
      }

      .button-check {
        opacity: 1;
        width: 20px;
        height: 20px;
      }

      .description {
        color: #000000;
        text-align: left;
        font-size: 12px;
        font-family: Tajawal;
        letter-spacing: 0px;
        opacity: 1;
        margin-left: 30px;
      }
    }
  }

  .offer-price-cards {
    background: #0000000f;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    opacity: 0.8;

    .subject-card {
      background: white;
      margin: 10px;
      display: flex;
      border-radius: 5px;
      justify-content: space-between;
      cursor: pointer;

      .subject-name {
        padding: 10px;
        font-family: Tajawal;
        font-weight: 400;

        .selected-subject {
          color: white;
        }
      }

      .subject-price {
        color: red;
        padding: 10px;
        font-weight: bold;
        font-family: Tajwal;
      }
    }
  }
}

#Remise {
  font-family: system-ui !important;
  text-decoration: none !important;
  font-weight: 1000 !important;
  border-radius: 10%;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: 15px;
  border: 2px solid white;
  background-color: red;
  color: white;
  background-clip: padding-box;
  padding: 3px;
  margin-left: 5px;

  @media screen and (max-width: 1351px) {
    font-size: 10px;
  }

  #dinnar {
    font-size: 12px;
    padding: top 20 !important;
    vertical-align: super;

    @media screen and (max-width: 1351px) {
      font-size: 8px;
    }
  }
}

.offer-subject {
  height: 1000px !important;
  background-color: #000000;
}

.rtl {
  .offers-card {
    .offer-card {
      .offer-periods {
        flex-direction: row-reverse;

        .period:first-child {
          border-radius: 0px 5px 5px 0px;
        }

        .period:last-child {
          border-radius: 5px 0px 0px 5px;
          border-left: solid;
        }

        .selected-period {
          border-radius: none;
        }
      }
    }

    .offer-price-cards {
      .subject-card {
        direction: rtl;
      }
    }
  }
}

.unsubscribe-btn{
  margin-top: 10px;
  position: relative !important;
 border-radius: 50%;
 border: none;
 width: 20px;
 height: 20px;
 background-color: #00000000;
 .unsubscribe-icon{
   cursor: pointer;
   color: white;
   height: 20px;
   width: 20px;
   font-size: 20px;
 }
}

