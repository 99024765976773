@import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');
.examCard {
  min-height: 180px;
  border: 1px #000 solid;
  border-radius: 10px !important;
  box-shadow: none;
  display: flex;
  text-transform: none;
  justify-content: space-between;
  margin: 15px 5px 10px 5px;
  .examCardContent {
    width: 100%;
    padding: 10px 15px;
    &:last-child {
      padding-bottom: 10px;
    }
    @media screen and (max-width: 425px) {

    }
  }
  @media screen and (max-width: 1836px) {
    margin: 15px 80px;
  }
  @media screen and (max-width: 1644px) {
    margin: 15px 50px;
  }
  @media screen and (max-width: 1550px) {
    margin: 15px 20px;
  }
  @media screen and (min-width: 1330px) and (max-width: 1450px) {
    margin: 15px 5px;
  }
  @media screen and (max-width: 599px) {
    width: 500px;
  }
  @media screen and (max-width: 425px) {
    width: 300px;
  }
  @media screen and (max-width: 350px) {
    width: 200px;
  }
  .examDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
    @media screen and (min-width: 700px) {
      min-width: 450px;
    }
    @media screen and (max-width: 425px) {
      flex-direction: column;
      align-items: flex-start;
      min-width: 100%;
    }
  }

  .examDateExamCard {
    color: red;
    //padding-bottom: 15px;
    display: inline;
    text-shadow: 0 0 0 #f67575;
    @media screen and (max-width: 425px) {
      font-size: 12px !important;
      padding-bottom: 5px;
    }
  }
  .textLink {
    text-decoration: none;
    color: white;
  }
}
.examTitle {
  font-weight: Bold !important;
  padding-top: 10px !important;
  font-size: 20px !important;
  @media screen and (max-width: 350px) {
    font-size: 10px !important;
  }
}
.teacherName {
  color: gray;
  font-weight: bold !important;
  font-size: 18px !important;
  @media screen and (max-width: 425px) {
    font-size: 13px !important;
  }
}
