.subject-card {
  flex-wrap: wrap;
  display: flex;
  line-height: 1em;

  .root {
    top: 139px;
    left: 315px;
    width: 179px;
    height: 147px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #2ba7df29 !important;
    border-radius: 10px;
    opacity: 1;
    padding-top: 0px;
    padding-bottom: 0px;
    @include mq(1380px, min) {
      width: 200px;
      height: 167px;
    }

    @include mq(960px, max) {
      width: 149px;
      height: 127px;
    }
  }
  .media {
    width: 54px;
    height: 54px;
    padding: 0;
    margin-left: 63px;
    @include mq(1280px, min) {
      margin-left: 73px;
    }
    @include mq(960px, max) {
      margin-left: 53px;
    }
  }
  .media2 {
    width: 100px;
    height: 54px;
    margin-top: 20px;
    margin-left: 20px;
    padding: 0;
  }
  .title-subject {
    color: $darker_blue;
    font-family: normal normal medium 16px/22px Tajawal;
    //letter-spacing: 0px;
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px;
    //text-align: left;
    opacity: 1;
  }
  .title-detailed {
    color: $darker_blue;
    flex-wrap: wrap;
    width: 100%;
    font-size: 20px;
    padding-top: 30px;
    padding-bottom: 0px;
    font-family: normal normal medium 20px/28px Tajawal;
    margin-left: 5px;
    margin-right: 5px;
    text-align: left;
    opacity: 1;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    flex-wrap: wrap;
    display: flex;
    white-space: nowrap;
  }
  .custom {
    border: none;
    box-shadow: none;
    display: flex;
    justify-content: start;
    width: 100%;
    height: 100%;
    padding: 5px;
  }
}
