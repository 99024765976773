.table-responsive {
  width: 100%;
  //   overflow: auto;
  height: 350px;
  position: relative;
  & > .table-container {
    max-height: 320px;
    overflow: auto;
    position: absolute;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(245, 245, 245, 0.6) !important;
  }
  & > .MuiTablePagination-root {
    margin-top: 320px;
    position: absolute;
    & >.MuiTablePagination-actions {
      direction: initial;
    }
  }
  & th,
  td {
    min-width: max-content;
    white-space: nowrap;
    border-bottom: none;
    padding: 2px 4px;
    text-align: center;
  }
  & .MuiTableCell-stickyHeader {
    // background-color: #bfe4f5 !important;
    background-color: #ebebf3 !important;
    padding: 2px 6px;
    color: #ababb0;
    text-align: center;
  }
  & .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(245, 245, 245, 0.6);
  }
  .tab-nav-link {
    text-decoration: none;
    color: black;
  }
}
.rtl{
  .table-responsive{
    direction: rtl;
    & .MuiTableCell-root{
      direction: ltr;
    }
  }
    .MuiTablePagination-actions {
           direction: ltr;
        }
  
}