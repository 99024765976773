.tab-item {
  max-width: 1500px;
  min-height: 143px;
  border-width: 1px;
  border-radius: 10px;
  /* border-color: #2ba7df; */
  box-shadow: none;
  /* border-style: solid; */
  display: flex;
  text-transform: none;
  flex-direction: row;
  overflow: auto;
  margin: auto;
}
.card {
  width: 100%;
}
.title-item {
  font-family: tajawal;
  font-size: 29px !important;
  color: #18698d;
  opacity: 1;
  @include mq(948px, max) {
    font-size: 20px !important;
  }
}
.index {
  color: #ed5454;
  font-family: tajawal;
  font-size: 29px !important;
  font-weight: bold !important;
  @include mq(948px, max) {
    font-size: 20px !important;
  }
}
.teacherName {
  color: gray;
  font-weight: bold;
  opacity: 1;
}
.infos {
  color: #a2a2a7;
  opacity: 1;
  font-size: 12px;
}
.details {
  //display: flex;
  //justify-content: space-between;
  //margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  margin: 10px 5px;
}

.spaceTitle {
  //display: flex;
  //justify-content: start;
  //margin-top: 19px;
  //margin-left: 12px;
  //margin-right: 12px;
  //margin: 5px 0px;
  @include mq(948px, max) {
    padding: 0px;
    margin: 0px;
  }
}
.CardContent {
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;

  @include mq(948px, max) {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.card-message {
  width: 100%;
  padding: 0px !important;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  .card-message-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
}
.title-inbox {
  font-family: Tajawal !important;
  color: white;
  opacity: 1;
  font-weight: bold !important;
  font-size: 29px;
  padding: 8px;
  background: transparent linear-gradient(80deg, #40bcf9, #2db3f3) 0 0 no-repeat padding-box;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  opacity: 0.8;
}
.inbox-body {
  color: $pure_black;
  opacity: 1;
  font-size: 14px;
  margin: 20px 0px !important;
}
