.language-dev{
    display: 'inline-block';
    padding: 0;
      .languages , .languages-whiteBox{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: bold !important;
        justify-content: space-between;
        font-size: 12px;
        text-transform: capitalize;
        position: relative;
        margin-bottom: 10px;
        border: none;
        white-space: normal;
         .select{
          display: flex;
          justify-content: center;
          color: black !important;
          font-size: 30px;
          padding: 0;  
          position: relative;
        }
        .MuiSvgIcon-root{
          color: white;
          display: none;
        }
        .MuiSelect-select{
          padding-right: 0px;
          padding-top: 22px;
          padding-left: 0px;
          width: min-content;
        }
        .flag{
          align-items: center;
          height: 28px;
          position: relative;
        }
        .text{
          position: relative;
          display: table-cell;
          margin: 0px 14px 14px 14px;
          padding: 0px 14px 14px 12px;
        }   
     }
     .languages-whiteBox{
      color: #6c757d !important;
     }

     .languages{
     color: $pure_white !important;
     }
  } 
  .languages-full{
    cursor: pointer;
    display: inline flex;
    margin-bottom: 28px;
    padding-top: 30px;
    .flag{
      align-items:center;
      height: 26px;
      position: relative;
      opacity: 0.7;

    }
    .flag-selected{
      align-items: center;
      height: 28px;
      position: relative;
    }
  }