.point-transfer {
  width: 100%;
  height: 50px;
  position: relative;
  background: rgba(25, 51, 222, 0.1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 14px 15px #0000000f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    background: #bfe4f5;
  }
  .transferText {
    color: #0d0e0f;
    text-align: center;
    font-family: tajawal;
    font-weight: bold;
    font-size: 14px;
    opacity: 1;
    text-transform: none;
  }
  .addPointsIcon {
    // filter: invert(19%) sepia(100%) saturate(2690%) hue-rotate(229deg) brightness(85%)
    //   contrast(108%);
    // padding-right: 20px;
    height: auto;
    width: 60px;
    padding-right: 16px ;
  }
}
