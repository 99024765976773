@import '../Mobile/calenderMobile';
.calender {
  padding: 15px;
  display: flex;
  flex-direction: column;
  .student-levels {
    display: flex;
    justify-content: center;
    //border: 2px solid $darker_blue;
    margin: 0px auto 35px auto;
    background: white;
    border-radius: 5px;
    align-items: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    .levels-card {
      margin: 10px;
      .subject-name {
        color: #a2a2a7;
        font-weight: 400;
        margin-bottom: 3px;
        font-size: 15px;
      }
      .student-level {
        font-weight: 500;
        color: $blue;
        margin-top: 3px;
      }
    }
  }
}

.table {
  border: none;
}

.week {
  display: flex !important;
  background-color: white;

  th:first-child {
    flex-basis: 120px;
    flex-grow: 0;
    opacity: 1;
    border: transparent;
    background-color: #f8f8fd;
  }
}

.weekBlank {
  background-color: transparent !important;
  display: flex !important;
  height: 20px;
  th:first-child {
    border: transparent;
    background-color: #f8f8fd;
    flex-basis: 120px;
    flex-grow: 0;
  }
}

.blankWeekDay {
  border-color: transparent;
  border-width: 1px;
  padding: 5px;
  border-style: solid;
  flex: 1;
  display: flex !important;
  min-width: 1%;
  border-top-width: 2px;
  height: 5px !important;
  padding: 8px 16px !important;
}

.currentBlankWeekDay {
  border-left: 3px $darker_blue solid;
  border-right: 3px $darker_blue solid;
  flex: 1;
  display: flex !important;
  min-width: 1%;
  padding: 1px 15px 0px 18px !important;
}

.weekDay {
  border-top-color: $grey;
}

.weekDay,
.currentWeekDay {
  border-top-width: 2px;
  border-style: solid;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 60px;
  flex-direction: column;
  display: flex !important;
  min-width: 1%;
  align-items: start;
  opacity: 0.5;
  overflow: hidden;
}

.currentWeekDay {
  border-color: $darker_blue;
  border-width: 3.5px;
  opacity: 1;
}

.dayOfWeekName {
  padding-top: 10px;
  font-weight: bold;
  color: $darker_blue;
  font-size: 20px;
  //font-family: Tajawal !important;
}

.dayOfWeekDate {
  padding-bottom: 10px;
  color: $grey;
  font-weight: bold;
}

.changeWeek {
  display: flex;
  color: black;
  justify-content: center;
  padding-bottom: 10px;
  background-color: white;
  margin-left: 120px;
  height: 60px;
}

.calenderBody {
  display: flex !important;

  td:first-child {
    flex-basis: 120px;
    flex-grow: 0;
    border-image: linear-gradient(to left, $lighter_grey 20%, rgba(0, 0, 0, 0) 20%);
    border-image-slice: 1;
    text-align: center;
    padding: 0;
  }

  > td {
    padding: 0;
  }

  td:first-child div {
    position: relative;
    opacity: 0.5;
    top: -12px;
  }
}

.calenderEvent {
  flex: 1;
  background-color: white;
  border: $grey solid 0px;
  border-right: $lighter_grey solid 1px;
  height: 40px;
  min-width: 1%;
}

.calenderTable tr:last-child > td:first-child {
  border-bottom: none;
}

.blankHeader {
  display: flex !important;
}

.headerDate {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.headerIcon {
  color: red;
  width: 29px !important;
  height: 40px !important;
  cursor: pointer;
}

.headerDateText {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-family: Tajawal !important;
  text-shadow: 0 0 0 black;
  margin-top: 10px !important;
  font-size: 26px !important;
  //font-size: 2.25rem;
  //font-weight: bold !important;
  //padding-top: 10px;
}

.refreshHeader {
  cursor: pointer;
  display: flex;
  align-self: flex-end;
  margin-left: auto;
  padding-right: 10px;
  padding-bottom: 10px;
}

.refreshText {
  color: #a2a2a7;
  height: 10px;
}

.singleEvent {
  z-index: 0;
  position: relative;
  background-color: white;
  border: $lighter_green 1px solid;
  border-radius: 5%;
  padding: 5px 7px !important;
  display: flex !important;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
  justify-content: space-evenly;
  opacity: 1 !important;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: hidden;

  &:hover {
    z-index: 90 !important;
    width: 99% !important;
  }
}

.calenderEventHours:hover {
  z-index: 1 !important;
}

.cardEventHeader {
  display: flex;
  width: 100%;
  padding-top: 1px;

  > div {
    padding-left: 10px;
  }
}

.eventName {
  font-weight: bold;
  color: $purple;
  flex: 0 1 auto;
  overflow: hidden;
  font-family: Tajawal !important;
}

.cardEventFooter {
  display: flex;
  overflow: hidden;
  width: 100%;
  justify-content: space-between;
  .cardEventTime {
    display: flex;
    font-weight: bold;

    > div {
      padding-right: 2px;
      color: $grey;
      font-size: 10px;
      font-family: 'Tajawal' !important;
    }
  }
  .eventStatus {
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
    height: 15px;

    .eventStatusText {
      background-color: $lighter_green;
      border-radius: 5px;
      color: white;
      font-weight: bold;
      padding: 4px 3px 3px 3px;
      align-items: center;
      display: flex;
      font-family: 'Tajawal' !important;
    }
  }
}

.currentDay {
  border-left: 3px $darker_blue solid;
  border-right: 3px $darker_blue solid;
  opacity: 1;
}

.lastCurrentDay {
  border-left: 3px $darker_blue solid;
  border-right: 3px $darker_blue solid;
  border-bottom: 3px $darker_blue solid !important;
  opacity: 1;
}

.rtl{
  .calender {
    direction: rtl; 
  }
  .table{
    direction: rtl; 
  }
  .calenderTable tr {
    direction: rtl; 
 }
  .headerDate {
    flex-direction: row-reverse;   
  }
  .changeWeek {
    flex-direction: row-reverse; 
    padding-bottom: 10px;
    margin-left: 0px;
    margin-right: 120px;
    height: 60px;
 }
 .headerDateText {
  direction: rtl; 
 }
}