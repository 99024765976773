.security-widget {
  .child-full {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > img {
      margin: 24px auto;
      width: 160px;
    }
  }
}
