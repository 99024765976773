.ta-dialog {
  & > .MuiDialog-container >  .MuiPaper-root {
    & > .MuiDialogTitle-root {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $blue;
      & > .MuiButtonBase-root {
        position: relative;
        top: auto;
        right: auto;
      }
    }
    & > .MuiDialogContent-root  {
      &>.content{
      overflow-y: hidden;}
      & > .ta-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        box-sizing: border-box;
        & > * {
          width: 100%;
          margin-bottom: 8px;
        }
        & > :last-child {
          margin-bottom: 0px;
        }
        & > .MuiFormControl-root > .fix-display {
          display: contents;
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
    // .rtl{
    //   direction: rtl;
    // }
  }
  & * {
    text-transform: none;
  }
}
.rtl{
  .ta-dialog {
  direction: rtl;
  & > .MuiDialog-container >  .MuiPaper-root 
    & > .MuiDialogTitle-root {
      direction: rtl;
    }
    & > .MuiDialogContent-root {
      direction: rtl;
    }
  }
}
