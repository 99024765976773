.subject-widget {
  padding-bottom: 0;
  & > .MuiCardContent-root {
    padding: 6px !important;
    & > .child-full {
      margin-bottom: 0;
      //   max-height: 800px;
      //   min-height: 569px;
      //   transition: all 1s ease;
      //   &.active {
      //     max-height: 2000px;
      //     min-height: 2000px;
      //   }
      & > .subject-title {
        display: flex;
        align-items: center;
        & > img {
          height: 60px;
        }
        & > span {
          font-size: 22px;
          margin-left: 12px;
          color: #18698d;
        }
      }
      & > .progression {
        & > .textes {
          display: flex;
          justify-content: space-between;
          font-size: 22px;
          color: #4a3c98;
          font-weight: 600;
          margin-bottom: 6px;
        }
        & > .MuiLinearProgress-root {
          background: #e3e3e4;
          height: 6px;
          border-radius: 6px;
          & > .MuiLinearProgress-bar {
            border-radius: 6px;
            background-color: $green;
          }
          &.bg-orange > .MuiLinearProgress-bar {
            background: #fe726e;
          }
          &.bg-green > .MuiLinearProgress-bar {
            background: #50bf88;
          }
          &.bg-purple > .MuiLinearProgress-bar {
            background: #4a3c98;
          }
        }
      }
      & > .subject-infos {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        & > span {
          color: #a9a9ae;
          font-size: 12px;
          margin-bottom: 8px;
          & > span {
            color: #18698d;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.rtl{
  .subject-widget {
    & > .MuiCardContent-root {
      & > .child-full {    
        & > .progression 
          & > .subject-infos{
               direction: rtl;
          }
        }
      }
    }
  }