.variant-btn,
.upload-btn {
  &.light-purple {
    background: #e7eafc !important;
    color: black !important;
    box-shadow: none !important;
    width: 100%;
    padding: 12px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: none !important;
    border-radius: 16px !important;
  }
  &.blue {
    background: #2ba7df !important;
    color: white !important;
    box-shadow: none !important;
    width: 100%;
    height: 30px;
    padding: 4px !important;
   // margin-top: 10px;
    font-weight: 500 !important;
    font-size: 14px !important;
    white-space: nowrap;
    text-transform: none !important;
    transition: all 300ms ease;
    &:hover {
      background: #40b1e4 !important;
    }
  }
  &.compte-button{
    height: 50px !important;
    margin-top: 10px;
  }
  &.blue-outlined {
    background: white !important;
    color: #2ba7df !important;
    border: 1px solid #2ba7df;
    box-shadow: none !important;
    width: 100%;
    padding: 4px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: none !important;
    transition: all 300ms ease;
    &:hover {
      background: #f4fcff !important;
    }
  }
  &.red {
    background: #ed5454 !important;
    color: white !important;
    box-shadow: none !important;
    width: 100%;
    height: 30px;
    padding: 4px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: none !important;
  }
  &.red-outlined {
    background: white !important;
    color: #ed5454 !important;
    border: 1px solid #ed5454;
    box-shadow: none !important;
    width: 100%;
    padding: 4px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-transform: none !important;
  }
  &.mt {
    margin-top: 12px;
    margin-bottom: -10px;
  }
  &.right {
    max-width: max-content;
    padding: 4px 8px !important;
    margin: 0 0 0 auto;
  }
  &.Mui-disabled {
    opacity: 0.6;
  }
}

.hidden {
  display: none;
}
