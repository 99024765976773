.locked-video {
  width: 100%;
  height: 480px;
  opacity: 1;
  background-color: #454850;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center !important;
  .iconStyle {
    color: #ffffff;
    width: 40px;
    height: 35px;
    opacity: 1;
  }
  .fabStyle {
    margin: 5px;
    background-color: #1877f2;
    box-shadow: #0000001c;
    width: 60px;
    height: 60px;
    opacity: 0.7;
  }
  .locked-text {
    color: $light_cyan;
    opacity: 1;
    font-size: 16px;
    opacity: 0.5;
    font-family: tajawal;
  }
  .locked-warning {
    color: $orange2;
    font-size: 16px;
    opacity: 0.8;
    font-family: tajawal;
  }
}
