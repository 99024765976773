.payment-methods-item {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 12px 6px;
  width: calc(100% / 3 - (4px * 2) / 3);
  min-width: min-content;
  margin-right: 4px;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  & > img {
    height: 48px;
    width: 48px;
    background: #ffeaea;
    padding: 12px;
    box-sizing: content-box;
    border-radius: 16px;
    transition: all 300ms ease;
    &.color-1 {
      background: #ffeaea;
      &:hover {
        background: #ebebf3;
      }
    }
    &.color-2 {
      background: #ffffff;
      border: 2px solid #f2f4fd;
      &:hover {
        background: #ebebf3;
      }
    }
    &.color-3 {
      background: #fbeadf;
      &:hover {
        background: #ebebf3;
      }
    }
  }
  & > .title {
    font-size: 12px;
    font-weight: 600;
    color: $pure_black;
    text-align: center;
    margin-top: 12px;
    line-height: 12px;
  }
}
