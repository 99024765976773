.subs-item-1 {
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  border-bottom: 1px solid $white2;
  & > .label {
    font-size: 12px;
    color: $grey;
    text-align: center;
  }
  & > .inline-btn-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 8px;
    & > .MuiButton-root {
      padding: 2px;
    }
    & > :not(:last-child) {
      margin-right: 4px;
    }
    & > .subs-btn {
      background: $lighter_blue;
      color: $darker_blue;
      text-transform: none;
    }
    & > .action-subs-btn {
      color: $blue;
      text-transform: none;
    }
    & > * {
      font-size: 12px;
    }
  }
}
