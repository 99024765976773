.actions-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > * {
    margin: 0 4px;
  }
}

.rtl {
  .actions-group {
    flex-direction: row-reverse;
  }
}
