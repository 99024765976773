.digitalVergo {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 140px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 0;


    .digitalVergo-title {
        font-size: 36px;
        font-weight: 700;
        font-family: 'Tajawal', sans-serif !important;
        @media screen and (max-width: 425px) {
            font-size: 30px !important;
        }
        @media screen and (max-width: 350px) {
            font-size: 25px !important;
        }
    }

    .digitalVergo-title-description {
        font-family: 'Tajawal', sans-serif !important;
        font-size: 18px;
    }

    .digitalVergo-cards {
        display: flex !important;
        flex-wrap: wrap !important;
        flex-direction: row-reverse!important;
        align-items: center!important;
        justify-content: center!important;
    }

}

.ooredoo-card {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    flex-wrap: wrap !important;

    .offer-section {
        font-size: 20px;
        font-weight: 700;
        color: #35BBE3;
        text-decoration: underline;
        margin: 35px;
    }

    .offer-card-ooredoo {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 606px;
        width: 365px;
        border: 4px solid #D7F1F9;
        border-radius: 32px;
        margin: 15px;
        @media screen and (max-width: 425px) {
            max-width: 320px !important;
        }
        @media screen and (max-width: 350px) {
            max-width: 300px !important;
        }
        .offer-card-imag {
            display: flex;
            flex-direction: column;
            align-items: center !important;

            .offer-imag {
                height: 70px;
                width: 70px;
                margin-bottom: 20px;
            }

            .offer-img-title {
                font-weight: 1000;
                font-size: 25px;
                font-family: 'Tajawal', sans-serif !important;
                color: #35BBE3;

            }

            .offer-img-description {
                font-size: 18px;
                color: #545454;
                font-family: 'Tajawal', sans-serif !important;
            }
        }

        .offer-card-price {
            display: flex;
            flex-direction: column;
            width: 267px;
            padding: 20px 20px 20px 20px;
            background-color: #F2FAFD;
            height: 100px;
            border-radius: 18px;
            align-items: center;

            .price-discription {
                font-weight: bold;
                font-size: 25px;
                font-family: 'Tajawal', sans-serif !important;
                text-align: right;
                direction: rtl !important;


            }
        }

        .offer-card-description {
            font-size: 15px;
            font-family: 'Tajawal', sans-serif !important;
            height: 100px;
            text-align: right;

            .descripton-text {
                display: flex;
                justify-content: end;
                margin: 10px;


                .vector {
                    margin: 5px;

                }

                .text {
                    font-size: 15px;
                    text-align: right;
                }
            }
        }

        .offerCard-btn {
            font-family: 'Tajawal', sans-serif !important;
            width: 267px;
            height: 52px;
            border-radius: 31px !important;
            background-color: #35BBE3 !important;
            color: white;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                opacity: 0.8 !important;
            }
        }
    }
}

.DigitalVergoModalContent {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: space-around;

    #inputTitle {
        font-size: 18px !important;
        font-family: 'Tajawal', sans-serif !important;
        font-weight: 700;
        margin-bottom: 5px;
        margin-top: 20px;
    }

    img {
        width: 100px;
        height: 90px;
        margin-bottom: 30px;
    }

    .classeinput {
        .classes-selector {
            width: 400px;
            height: 30px;
            border-radius: 32px !important;
        }
    }

    .ooredooCards {
        display: flex;
        flex-direction: row !important;
    }

    & .Ooredoo-box {
        overflow: hidden;
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        height: 300px!important;
        margin: 10px;
        background-color: rgba(220, 220, 220, 0.241);
        border-radius: 10px;
        margin-bottom: 40px;
        width: 100%;

        @include mq(775px, max) {

            height: 258px;
        }

        .ooredoo-icon {
            display: flex;
            flex-direction: row;
            position: relative;
            right: 30px;

            .ooredooImg {
                box-shadow: 1px 1px 1px gray;
                width: 100px !important;
                height: 60px !important;
                border-radius: 5%;
                margin-right: 20px;
                margin-bottom: 0px;

                @include mq(775px, max) {

                    margin-right: 0px
                }
            }

            @include mq(775px, max) {
                justify-content: center;
                flex-direction: column;
                align-items: center;
                right: 0;
                top: 20px;
                margin-right: 0px
            }
        }

        .ooreedoo-price {
            position: relative;
            top: 20px;
            font-weight: bold;
            font-size: 14px;
            vertical-align: bottom !important;
            margin-bottom: 20px;

        }


        .acheter-mini {
            width: 139px !important;
            height: 29.61px !important;
            justify-content: center;
            width: 100%;
            color: white !important;
            font-weight: bold !important;
            font-size: 14px;
            text-transform: none !important;
            height: 40px !important;
            margin-bottom: 10px !important;
            display: flex;
            border-radius: 10px !important;
            background-color: #35BBE3 !important;
            box-shadow: 0px 4px 13px 2px rgba(84, 168, 238, 0.33);
            cursor: pointer;

            &:hover {

                filter: opacity(0.9)
            }

            &:disabled {
                background-color: gray !important;
                opacity: 30% !important;
            }

            @include mq(775px, max) {
                width: 110px !important;

            }
        }

    }
}

.Ooredoo-box {
    overflow: hidden;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    height: 270px;
    margin: 10px;
    background-color: rgba(220, 220, 220, 0.241);
    border-radius: 10px;
    margin-bottom: 40px;
    width: 100%;

    @include mq(775px, max) {

        height: 258px;
    }

    .ooredoo-icon {
        display: flex;
        flex-direction: row;
        position: relative;
        right: 30px;

        .ooredooImg {
            box-shadow: 1px 1px 1px gray;
            width: 100px !important;
            height: 60px !important;
            border-radius: 5%;
            margin-right: 20px;

        }

    }



    .acheter-mini {
        width: 139px !important;
        height: 29.61px !important;
        justify-content: center;
        width: 100%;
        color: white !important;
        font-weight: bold !important;
        font-size: 14px;
        text-transform: none !important;
        height: 40px !important;
        margin-bottom: 10px !important;
        display: flex;
        border-radius: 10px !important;
        background-color: #35BBE3 !important;
        box-shadow: 0px 4px 13px 2px rgba(84, 168, 238, 0.33);
        cursor: pointer;

        &:hover {

            filter: opacity(0.9)
        }

        &:disabled {
            background-color: gray !important;
            opacity: 30% !important;
        }

        @include mq(775px, max) {
            width: 110px !important;

        }
    }

}


.paiment-ooreedoo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    @include mq(775px, max) {
        width: auto;

    }
}

.text-ooreedoo {
    font-size: 11px;
    margin: 20px;
    text-align: center;

    @include mq(775px, max) {
        font-size: 10px;
    }
}

.text-paiment-ooreedoo {
    font-size: 12px;
    margin: 20px;
    text-align: center
}

.ooreedoo-price {
    position: relative;
    top: 20px;
    font-weight: bold;
    font-size: 14px;
    vertical-align: bottom !important
}

.Paiment-titel {
    font-weight: 400;
    font-size: 31px;
    margin-top: 40px;
}



.remove-icon {
    color: white;
    width: 25px !important;
    height: 25px !important;
    cursor: pointer;

    &:hover {

        filter: opacity(0.6)
    }

}


.navigation {

    display: flex;
    align-items: center !important;
    margin-left: auto;

    .navigation-login {
        text-decoration: underline;
        font-size: 18px;
        font-weight: bold;
        color: #35BBE3 !important;
        margin: 10px;

    }

    .navigation-register {
        text-decoration: underline;
        font-size: 18px;
        font-weight: bold;
        color: #35BBE3 !important
    }

}