.empty {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #d6e0ec;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  margin-top: 0 !important;
  &.no-borders {
    border: none;
  }
  & > img {
    height: 32px;
    margin-right: 12px;
  }
  & > span {
    font-size: 12px;
    color: #a2a2a7;
  }
}
