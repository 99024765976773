.div-transfert {
  background: #dfe1f3 0% 0% no-repeat padding-box;
  box-shadow: 0px 14px 15px #0000000f;
  color: #0d0e0f;
  border-radius: 12px;
  text-align: center;
  margin: auto;
  .button-transfert {
    padding: 25px 30px;
    border-radius: 12px;
    width: 100%;
    text-transform: capitalize;
  }
  .arrow-button {
    margin-right: 5px;
  }
}
