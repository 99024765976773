.calender-mobile {
  background-color: white;
  min-height: 500px;

  .calender-mobile-header {
    border-radius: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .changeWeekMobile {
      //min-width: 375px;
      min-height: 60px;
      background-color: white;

      .headerDateMobile {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 16px;
      }

      .headerIconMobile {
        color: red;
        width: 28px !important;
        height: 43px !important;
        cursor: pointer;
      }

      .headerDateTextMobile {
        font-size: 18px;
        color: #18698d;
        font-weight: bold;
        font-family: Tajawal !important;
        margin-top: 7px;
      }
    }

    .weekMobile {
      background-color: white;
      height: 86px;
      display: flex;
      border-radius: 0px 0px 20px 20px;
      justify-content: space-evenly;
      padding: 10px;

      .weekDayMobile {
        display: flex;
        flex-direction: column;
        align-items: center;

        .weekDayNameMobile {
          margin-bottom: 8px;
          color: $grey;
          font-size: 13px;
          font-weight: 400;
        }

        .weekDayNumberMobile {
          font-weight: 400;
          padding: 5px 10px;
        }

        .current {
          background-color: $blue !important;
          display: flex;
          justify-content: center;
          padding: 0px 10px;
          color: white;
          border-radius: 10px;
          padding: 5px 10px;
        }
      }

      .current-weekDay {
        border-bottom: 2px solid #dadef0;
      }

      //min-width: 375px;
    }
  }
  .calender-mobile-container {
    padding: 18px;
    display: flex;
    flex-direction: column;

    .event-card-header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .event-day {
        font-size: 20px;
        color: $darker_blue;
        font-weight: bold;
      }

      .event-day-name {
        margin-left: 10px;
        color: $blue;
        font-weight: 500;
      }
    }
    .event {
      display: flex;
      flex-direction: column;
      border: 1px solid #3aaaff;
      margin-bottom: 10px;
      border-radius: 5px;
      padding: 10px;
      height: 74px;
      box-shadow: #2ba7df33 0px 3px 8px;
      cursor: pointer;
      .event-header {
        display: flex;
        justify-content: space-between;
        //align-items: center;

        .event-subject-image {
          width: 25px;
          margin-right: 5px;
        }

        .event-name {
          color: $purple;
          font-family: Tajawal !important;
          //font-size: 20px;
          font-weight: bold;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 25px;
        }
      }
      .event-status {
        font-size: 10px;
        display: flex;
        justify-content: flex-end;
        height: 15px;

        .event-status-text {
          background-color: $lighter_green;
          border-radius: 5px;
          color: white;
          font-weight: bold;
          padding: 10px 5px 7px 5px;
          align-items: center;
          display: flex;
          font-family: 'Tajawal' !important;
        }
        .event-status-in-progress {
          background-color: #EE6969;
          border-radius: 5px;
          color: white;
          font-weight: bold;
          padding: 10px 5px 7px 5px;
          align-items: center;
          display: flex;
          font-family: 'Tajawal' !important;
        }
        .disabled {
          background-color: $blue !important;
        }
      }
      .event-time {
        display: flex;
        justify-content: flex-start;
        margin: 5px 0px;

        > div {
          margin-right: 5px;
          color: $grey;
          font-weight: 500;
          font-size: 13px;
        }
      }
      &:hover {
        background-color: rgba(233, 248, 253, 0.6);
      }
    }
  }
}
.levels-container {
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-y: scroll;
  position: center;
  white-space: nowrap;
  .student-levels {
    display: flex;
    justify-content: center;
    margin: 0px auto 0px auto;
    padding-top: 0;
    background: white;
    border-radius: 5px;
    align-items: center;
    padding: 10px;
    box-shadow: rgba(158, 96, 96, 0.16) 0px 1px 4px;
    .levels-card {
      margin: 10px;
      .subject-name {
        color: #a2a2a7;
        font-weight: 400;
        margin-bottom: 3px;
        font-size: 15px;
      }
      .student-level {
        font-weight: 500;
        color: $blue;
        margin-top: 3px;
      }
    }
  }
}

.rtl{
  .calender-mobile {
    //flex-direction: row-reverse;

    .calender-mobile-header {
      .changeWeekMobile {
        .headerDateTextMobile {
          direction: rtl; 
        }
      }
      .weekMobile {
        direction: rtl; 
       // flex-direction: row-reverse;
      }
    }
    .calender-mobile-container {
      .event-card-header {
        flex-direction: row-reverse;
        .event-day {
          direction: rtl; 

        }
      }

    }
  }
}
