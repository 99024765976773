.confirmationModal {

  .confirmationTitle > * {
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
   margin-top: -20px;
   margin-bottom: 20px;
  }
  .confirmation-img {
    max-height: 80px;
    //display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-close-confirmation {
    font-weight: bold !important;
    display: flex;
    justify-content: flex-end;;
    cursor: pointer;
  }
  .close-confirmation {
    color: $grey !important;
    font-weight: bold !important;
    font-size: 18px;
    opacity: 1;
    &:hover {
      text-shadow: 0px 0px 0.5px $grey;
    }
  }
 
  .message-confirmation {
    font-size: 14px;
    display: flex;
    justify-content: center;
    font-weight: bold !important;
    color: $lighter_black;
   text-align: center;
   margin-bottom: 40px;
    
  }


  .content-confirmation {
    min-height:60px;
    margin-right: auto;
    margin-left: auto;
  }
  .confirmation {
    display: flex;
    background-color: $blue;
    justify-content: center;
    width: 30%;
    margin: auto;
    color: white !important;
    font-weight: bold !important;
    text-transform: none !important;
    height: 30%;
    border-radius: 8px;
    margin-bottom: 15px;
    &:hover {
      filter: saturate(70%);
    }
  }


  
}
