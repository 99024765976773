.warning-card {
  background: linear-gradient(112deg, rgb(239, 129, 45) 0%, rgb(236, 73, 73) 100%) 0% 0% no-repeat
    padding-box padding-box transparent;
  display: flex;
  .card-container {
    width: 100%;
    color: white;
    display: flex;
    .warning-description {
      width: 100%;
      .warning-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .offer-name {
          text-shadow: 0px 1px 1px #00000040;
          font-size: 23px;
          font-weight: bold;
        }
        .show-more {
          cursor: pointer;
          display: flex;
          align-items: center;
          text-decoration: none;
          color: white;
          &:hover {
            text-shadow: 0px 1px 1px #00000040;
          }
          .show-more-icon {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .warning-details {
      .rest-period {
        margin: 10px;
      }
    }
    .warning-actions {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .upgrade-button {
        border: 1px solid white;
        border-radius: 5px;
        padding: 5px 15px;
        cursor: pointer;
        font-weight: bold;
        text-decoration: none;
        color: white;
        &:hover {
          color: #ec4949;
          background-color: white;
        }
      }
      .show-more {
        cursor: pointer;
        font-weight: bold;
        text-decoration: none;
        color: white;
        &:hover {
          text-shadow: 0px 1px 1px #00000040;
        }
        @media screen and (max-width: 670px) {
          display: none;
        }
      }
    }
  }
}
.rtl{
  .warning-card {
    .card-container {
      .warning-description {
        direction: rtl;
      }
      .warning-header {
        .show-more {
          .show-more-icon {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

