.video-title {
  flex-grow: 1;
  width: 100%;
  .title {
    color: #ffffff;
    font-size: 22px;
    font-family: tajawal;
    opacity: 1;
    letter-spacing: 0;
    text-align: left;
  }
}
