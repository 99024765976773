.div-button-add-point {
  .button-add-point {
    background: #2ba7df 0% 0% no-repeat padding-box;
    box-shadow: 0px 14px 15px #0000000f;
    color: #fff;
    border-radius: 6px;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
    padding: 10px;
  }
}
