.progress {
  flex-grow: 1;

  .number {
    color: $purple;
    opacity: 1;
    font-size: 20px;
    font-weight: Bold;
    display: flex;
    justify-content: space-between;
  }
  .icon-subject {
    width: 18px;
    height: 20px;
    opacity: 1;
  }
  .block {
    display: flex;
    justify-content: space-between;
  }
  .block2 {
    display: flex;
    justify-content: space-between;
  }
}
