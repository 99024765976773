.videoCard {

  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 20px 30px  !important;
  //position: relative;
  top: -0.4rem;
  max-width: 320px;
  max-height: 250px;
  .card-assistance {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .assistance-card-img {
    height: 80%;
    width: 100%;
  }
  .assistance-card-title {
    height: 70px;
    text-align: center !important;
  }
  .assistance-title {
    //display: inline-block;
    position: relative;
    font-size: 16px;
    font-weight: bold;
    font-family: tajawal;
    text-align: center;
    margin: 0px;
    padding: 0px;
    bottom: 15px;
  }
  .card-img-top {
    width: 100%;
  }
}

.youtube-video-modal {
  overflow: hidden;
  padding: 0 !important;
  background: black !important;
  border: 6px solid white !important;
  & > .videoModal {
    overflow: hidden;
    width: 588px !important;
    height: 331px !important;
    margin: 0 auto !important;
  }
}
