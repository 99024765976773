.wallet-details {
  height: 100%;
  & > .MuiCardContent-root {
    flex-direction: row;
    @include mq(1380px, max) {
      flex-direction: column-reverse;
    }
    & > .child-right {
      @include mq(1380px, min) {
        margin-left: 16px;
      }
      @include mq(1380px, max) {
        margin-left: 0;
      }
    }
    & > .child-left {
      @include mq(1380px, min) {
        margin-right: 16px;
      }
    }
  }
}
