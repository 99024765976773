.transfer {
  max-width: 662px;
  max-height: 321px;
  display: flex;
  flex-direction: column;
  // @include mqs(943px, 600px) {
  //   flex-direction: column;
  // }
  // @include mqs(600px, 400px) {
  //   flex-direction: row;
  // }
  // @include mq(400px, max) {
  //   flex-direction: column;
  // }
  @media screen and (max-width:360px){
    max-height: 300px;
   }
  .title-modal {
    color: #2ba7df;
    opacity: 1;
    font-family: Tajawal;
    font-size: 20px;
  }
  .box-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 20px;
    @include mq(560px, max) {
      margin-left: 20px !important;
      margin-top: 0px;
    }
  }
  .button-modal {
    color: #2ba7df;
    justify-content: center;
    font-family: Tajawal;
    font-size: 12px;
    font-weight: Bold;
    opacity: 1;
    @include mq(560px, max) {
      font-size: 10px !important;
    }
  }
  .img-modal {
    width: 130px !important;
    height: 128px !important;
    margin-left: 30px !important;
    margin-top: 10px !important;
    margin: 5px !important;
    @include mqs(943px, 600px) {
      width: 80px !important;
      height: 70px !important;
    }
    // @include mqs(600px, 400px) {
    //   flex-direction: row;
    // }
    // @include mq(400px, max) {
    //   flex-direction: column;
    // }
    @include mq(560px, max) {
      width: 80px !important;
      height: 70px !important;
    }
  }
  .text-modal {
    color: #0d0e0f !important;
    font-family: Tajawal !important;
    font-size: 12px !important;
    margin-left: 10px !important;
    @include mqs(943px, 600px) {
      width: 80px !important;
      height: 70px !important;
    }
    @include mq(948px, max) {
      width: 80px !important;
      height: 70px !important;
    }
    &.rtl {
      direction: rtl;
      flex-direction: column !important;
      padding-right: 20px;
    }
  }
  .div-modal {
    display: flex;
    flex-direction: row;
    // @include mq(560px, max) {
    //   flex: column;
    //   .five { order: 1; }
    //   .four { order: 2;  }
    //   .three { order: 3; }
    // }
  }

  .group-text {
    @include mq(560px, max) {
      display: none !important;
    }
  }
  .text-info {
    color: $blue;
  }
}
