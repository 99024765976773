.view-title {
  color: $blue;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  @include mq(780px, max) {
    font-size: 24px;
  }
  &.orange {
    color: $orange;
  }
}

.rtl {
  .view-title {
    //  direction: rtl;
    //  position:relative;
    //  width: 80%;
    //  @include mq(1380px, max) {
    //    width: calc(64% - 8px);

    //  }
    text-align: right;
  }
}
