.banque-card {
  background: $pure_white 0% 0% no-repeat padding-box;
  border: 1px solid $blue_dark;
  border-radius: 6px;
  margin: 0px 0px 10px 0px;
  padding: 5px 10px;
  .banque-info-name {
    font-weight: bold;
    color: $blue3;
    margin-bottom: 5px;
    line-height: 3;
  }
  .banque-info {
    color: $blue3;
    margin-bottom: 5px;
  }
  .banque-info-grey {
    color: #b6b6c7;
  }
}
