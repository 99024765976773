.magazine {
  margin: 0 auto;
  width: 100%;
  height: 100%;

  -webkit-box-shadow: 0 0 10px #999;
  -moz-box-shadow: 0 0 10px #999;
  -ms-box-shadow: 0 0 10px #999;
  -o-box-shadow: 0 0 10px #999;
  box-shadow: 0 0 10px #999;
  .page {
    height: 100%;
    width: 100%;
    img {
      max-width: 100%;
      height: 100%;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      /* user-drag: none; */
    }
  }
  .odd {
    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(0.95, #fff0),
        color-stop(1, #cacaca)
      );
      background-image: -webkit-linear-gradient(to right, #fff0 95%, #cacaca 100%);
      background-image: -moz-linear-gradient(to right, #fff0 95%, #cacaca 100%);
      background-image: -ms-linear-gradient(to right, #fff0 95%, #cacaca 100%);
      background-image: -o-linear-gradient(to right, #fff0 95%, #cacaca 100%);
      background-image: linear-gradient(to right, #fff0 95%, #cacaca 100%);
    }
  }
  .even {
    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0.95, #fff0),
        color-stop(1, #dadada)
      );
      background-image: -webkit-linear-gradient(to left, #fff0 95%, #dadada 100%);
      background-image: -moz-linear-gradient(to left, #fff0 95%, #dadada 100%);
      background-image: -ms-linear-gradient(to left, #fff0 95%, #dadada 100%);
      background-image: -o-linear-gradient(to left, #fff0 95%, #dadada 100%);
      background-image: linear-gradient(to left, #fff0 95%, #dadada 100%);
    }
  }
}
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0px;
  right: 0;
  overflow: hidden;
  z-index: 9999;
  background: #000;
  overflow-y: scroll;
  padding-left: 10px;
  padding-left: 10px;
}
.magazine-nav {
  position: absolute;
  top: 35%;
  font-size: 5em;
}
.magazine-nav.left {
  left: -25px;
}
.magazine-nav.right {
  right: -25px;
}
.magazine-control-bar {
  background: #ebebf3;
  width: 300px;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.magazine-control-bar li button {
  padding: 0px;
}
.flip-page {
  @include mq(960px, max) {
    max-width: 300px;
    max-height: 500px;
  }
}
