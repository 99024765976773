.root {
  width: 98%;
  display: flex;
  flex-wrap: wrap;
  //border-style: none;
  padding: 0px;
  margin-top: 15px;
  .group {
    background: #ffffff;
    width: 100%;
    display: flex;
    height: 26px;
    border: none;
    padding: 0px;
  }
  .active {
    background: #2ba7df;
    color: #ffffff;
    border-radius: 50px;
  }
  .title {
    color: #a2a2a7;
    font-family: tajawal;
    font-size: 10px;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    letter-spacing: 0;
    text-transform: capitalize;
    font-weight: bold;
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background: #2ba7df;
      color: #ffffff;
    }
    &:active {
      background: #2ba7df;
      color: #ffffff;
    }
  }
  title.MuiTouchRipple-root span {
    background: '#2BA7DF!important';
    color: '#FFFFFF';
    opacity: 0.3;
  }
  .tab {
    width: 60%;
  }
}
