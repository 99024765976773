.video-view {
  opacity: 1;
  background-color: transparent;
  max-height: 500px;
  width: 100%;

  .container1 {
    width: 100%;
    height: 100%;
    background: #18698d;
    display: flex;
    flex-direction: row;
    @include mq(960px, max) {
      flex-direction: column;
    }
  }
  .div1 {
    margin-left: 29px;
    padding-top: 20px;
    width: 70%;
    height: 100%;
    @include mq(960px, max) {
      width: 90%;
      height: 20%;
      margin-left: 10px;
    }
  }
  .div2 {
    width: 30%;
    //max-height: 300px;
    margin-left: 29px;
    margin-right: 10px;
    padding-top: 90px;
    margin-top: 80px;
    padding: 0px;
    @include mq(960px, max) {
      width: 90%;
     // height: 50%;
      margin-top: 0px;
      margin-left: 10px;
      overflow: hidden;
    }
  }
}
