.Popups{
  z-index:99999 !important;
}
.closeButton {
  background-color: $blue !important;
  color: white !important;
}
.ql-align-center {
  text-align: center;
}
.ql-align-right{
  text-align: right;
}
.ql-align-left{
  text-align: left;
}
.ql-align-justify{
  text-align: justify;
}
