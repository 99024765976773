.profile-widget-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
  & > :last-child {
    margin-top: 0 !important;
  }
  & > .header {
    color: $blue;
    font-size: 16px;
    text-align: center;
    border-bottom: 1px solid $white2;
    padding-bottom: 6px;
    margin-bottom: 6px;
    & > .bold {
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  .offers-list {
    max-height: 200px;
    overflow: auto;
    padding: 0 5px;
    text-align: center;
    & > .label {
      font-size: 12px;
      color: $grey;
      text-align: center;
      margin-top: 2px !important;
    }
  }

  & > .action-subs-btn {
    color: white;
    border: 1px solid white;
    box-shadow: 0px 0 8px 0 #ffffff8c;
    padding: 2px 8px;
    text-transform: none;
    margin-top: 8px !important;
  }
  & > hr {
    border: none;
    border-bottom: 1px solid $white2;
    width: 100%;
    margin-top: 8px !important;
  }
}
.rtl {
  .profile-widget-container {
    & > .header {
      direction: rtl;
    }
  }
}
