.wallet {
  & > .ta-column {
    & > .child-left,
    .child-right {
      @include mq(1380px, max) {
        width: calc(50% - 6px);
      }
    }
  }
}
