@import 'attachment';
@import 'hessatyModal';
.sessionModal {
  display: flex;
  flex-direction: column;
  &.rtl {
    direction: rtl;
    flex-direction: column !important;
  }
}
.sessionDescription {
  font-weight: bold !important;
  padding-top: 5px;
  @media screen and (max-width: 599px) {
    font-size: 15px !important;
  }
}
.sessionLevels {
  font-weight: bold !important;
  .sessionLevels {
    color: $grey;
  }
}
.infoSpan {
  color: $grey;
  font-weight: bold;
  @media screen and (max-width: 599px) {
    margin: 5px 0px;
  }
}
.infoValue {
  color: $blue;
  font-weight: lighter !important;
  margin-left: 5px !important;
  text-shadow: 0 0 0 $blue;
}
.description{
  color:$lighter_black;
  font-weight: lighter !important;
  margin-left: 5px !important;
  text-shadow: 0 0 0 $blue;
}
.sessionInfo {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 10px;
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}
.levels {
  border: #ed5454 solid 1px;
  border-radius: 4px;
  margin-left: 5px !important;
  color: #ed5454;
  padding: 2px 5px;
  font-size: 12px;
  justify-content: center;
  @media screen and (max-width: 599px) {
    font-size: 10px;
  }
}
.sessionDocuments {
  padding-top: 10px;
}
