@import 'examCard';
@import 'examDetails';
@import 'examDownloadButton';

//.exam-container {
//  width: 100%;
//  border-radius: 10px !important;
//  box-shadow: 0px 3px 10px #2ba7df29 !important;
//  margin-top: 20px;
//  min-height: 800px;
//  .card-header {
//    display: flex;
//    justify-content: space-between;
//
//    .filterText {
//      padding-top: 8px;
//      padding-right: 5px;
//    }
//  }
//  @media screen and (max-width: 425px) {
//    width: calc(64% - 8px);
//  }
//}

.exam-card-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .card-header {
    display: flex;
    justify-content: space-between;

    .filterText {
      padding-top: 8px;
      padding-right: 5px;
    }
  }
}
.cards {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.dropzone {
  background-color: #e2effa;
  color: white;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  height: 100px;

  .dropZoneText {
    display: flex;
    align-items: flex-end;
    color: $grey;
  }

  .dropzoneIcon {
    filter: invert(1) hue-rotate(1deg) contrast(1%) grayscale(2);
  }
}

.examButton {
  @include blueButton();
  @media screen and (max-width: 425px) {
    width: calc(70%) !important;
    //float: left !important;
  }
  //@media screen and (max-width: 350px) {
  //  width: 170px !important;
  //  float: left !important;
  //}
  //@media (max-width: 425px) and (min-width: 351px) {
  //  margin-left: 65px;
  //}
  .examButtonText {
    @include buttonText();
  }
}
.rtl{
  .exam-card-content {
   
  
  .cards {
    direction: rtl; 
    }
  }
}