.pubs-cards {
  background: white;
  padding: 12px;
  box-shadow: 0px 2px 8px rgba(179, 208, 232, 0.6);
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &.info {
    background: rgb(33, 139, 241);
    background: -moz-linear-gradient(315deg, rgba(33, 139, 241, 1) 0%, rgba(62, 185, 249, 1) 100%);
    background: -webkit-linear-gradient(
      315deg,
      rgba(33, 139, 241, 1) 0%,
      rgba(62, 185, 249, 1) 100%
    );
    background: linear-gradient(315deg, rgba(33, 139, 241, 1) 0%, rgba(62, 185, 249, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#218bf1",endColorstr="#3eb9f9",GradientType=1);
    flex-direction: row-reverse;
    & > .bg {
      top: -78px;
      left: -112px;
    }
  }
  &.success {
    background: rgb(4, 166, 245);
    background: -moz-linear-gradient(315deg, rgba(4, 166, 245, 1) 0%, rgba(39, 240, 158, 1) 100%);
    background: -webkit-linear-gradient(
      315deg,
      rgba(4, 166, 245, 1) 0%,
      rgba(39, 240, 158, 1) 100%
    );
    background: linear-gradient(315deg, rgba(4, 166, 245, 1) 0%, rgba(39, 240, 158, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#04a6f5",endColorstr="#27f09e",GradientType=1);
    flex-direction: row-reverse;
    & > .bg {
      //   top: -42px;
      //   left: -8px;
      top: -54px;
      left: -60px;
      width: 386px !important;
    }
  }
  &.warning {
    background: rgb(237, 78, 72);
    background: -moz-linear-gradient(315deg, rgba(237, 78, 72, 1) 0%, rgba(239, 126, 47, 1) 100%);
    background: -webkit-linear-gradient(
      315deg,
      rgba(237, 78, 72, 1) 0%,
      rgba(239, 126, 47, 1) 100%
    );
    background: linear-gradient(315deg, rgba(237, 78, 72, 1) 0%, rgba(239, 126, 47, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ed4e48",endColorstr="#ef7e2f",GradientType=1);
    & > .bg {
      top: -72px;
      left: 72px;
    }
  }
  &.anniversary {
    background: rgba(71, 200, 230, 1);
    background: -moz-linear-gradient(315deg, rgba(40, 154, 196, 1) 0%, rgba(71, 200, 230, 1) 100%);
    background: -webkit-linear-gradient(
      315deg,
      rgba(40, 154, 196, 1) 0%,
      rgba(71, 200, 230, 1) 100%
    );
    background: linear-gradient(315deg, rgba(40, 154, 196, 1) 0%, rgba(71, 200, 230, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#289AC4",endColorstr="#47C8E6",GradientType=1);
    & > .bg {
      top: -120px;
      left: 40px;
      filter: brightness(15);
      -moz-filter: brightness(15);
      -webkit-filter: brightness(15);
    }
  }

  & > .img {
    width: 60px;
    z-index: 8;
  }
  & > .text {
    width: 124px;
    z-index: 8;
  }
  & > .bg {
    width: 324px;
    opacity: 0.3;
    z-index: 4;
    position: absolute;
  }
}
.trial {
  background: linear-gradient(105.02deg, #ffbb01 16.24%, #ffaa01 95.37%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffbb01",endColorstr="#ffaa01",GradientType=1);

  & > .img {
    width: 110px !important;
  }

  & > .text {
    width: 98px !important;
  }
  & > .bg {
    top: -1px;
    left: -63px;
    width: 320px;
    filter: brightness(1);
    -moz-filter: brightness(1);
    -webkit-filter: brightness(1);
    opacity: 0.1;
  }
}
.pubs-main {
  width: 100%;
}
