.d17 {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
    .group-text-D17{
      display: flex;
      flex-direction: column;
     align-items: center;
     .text-modal-D17{
       font-size: 15px;
       display: flex;
      flex-direction: column;
     align-items: center;
     }
     .button-modal-D17{
        color:white ;
        background-color:#2ba7df ;
        justify-content: center;
        font-family: Tajawal;
        font-size: 15px;
        font-weight: Bold;
        opacity: 1;
        width: 120px !important;
        margin-top: 10px;
        margin-left: 5px;
        @include mq(560px, max) {
          font-size: 10px !important;
        }
     
    }}
   .image-modal{ 
      max-height: 250px !important;
      max-width: 250px !important;
  }
  .SpanRtl{
    direction: rtl;
  }
  .SpanlTR{
    direction: ltr;
  }
}
