.wallet-services {
  height: 100%;
}

.custom-services-child {
  @include mq(1280px, min) {
    margin-bottom: 0 !important;
    margin-top: 24px;
  }
}
