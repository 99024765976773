.MuiPickersToolbar-toolbar {
  background-color: $blue !important;
}
.MuiTypography-colorPrimary {
  color: $blue !important;
}
.MuiButton-textPrimary {
  color: $blue !important;
}
.MuiPickersDay-daySelected {
  background-color: $blue !important;
}
