.service-card {
  margin: 10px 6px;
  background: $grey_dark 0% 0% no-repeat padding-box;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  border: 1px solid $purple1;

  .white {
    background: $pure_white;
  }
  .red {
    background: $red_opac;
  }
  .orange {
    background: $orange_oopac;
  }

  .logo-service {
    text-align: center;

    width: 73px;
    height: 73px;
  }
  .service-card:hover {
    cursor: pointer;
  }

  .logo-service img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
  }
}
.text-center {
  text-align: center;
  letter-spacing: 0px;
  .name-service {
    width: 73px;
    height: 73px;
    margin: auto;
    font-weight: bold;
    color: #0d0e0f;
  }
}
