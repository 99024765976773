.video-path {
  display: flex;
  padding-bottom: 18px;
  .signe {
    margin: 10px;
    margin-left: 29px;
  }
  .title {
    color: #ffffff;
    opacity: 1;
    letter-spacing: 0;
    font-family: tajawal;
    font-size: 14px;
    @include mq(1380px, max) {
      font-size: 12px;
    }
  }
  .subTitle {
    color: #ffffff;
    opacity: 0.5;
    font-family: Tajawal;
    font-size: 14px;
    @include mq(1380px, max) {
      font-size: 12px;
    }
  }
  .separator {
    color: #1877f2;
    opacity: 1;
  }
}
