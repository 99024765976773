.my-progress-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid $white2;
  transition: all 300ms ease;
  & > :first-child {
    margin-right: 30px;
  }
  //   &:not(:last-child) {
  //     border-bottom: 1px solid $white2;
  //     margin-bottom: 8px;
  //   }
  & > .left {
    width: 100%;
    & > .title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      & > :first-child {
        margin-right: 8px;
      }
      & > span {
        font-size: 14px;
        font-weight: 500;
        color: $darker_blue;
      }
      & > img {
        width: 25px;
        padding-left: 5px;
      }
    }
    & > .MuiLinearProgress-root {
      background: #e3e3e4;
      height: 6px;
      border-radius: 6px;
      & > .MuiLinearProgress-bar {
        border-radius: 6px;
        // background-color: $green;
      }
      &.bg-orange > .MuiLinearProgress-bar {
        background: #fe726e;
      }
      &.bg-green > .MuiLinearProgress-bar {
        background: #50bf88;
      }
      &.bg-purple > .MuiLinearProgress-bar {
        background: #4a3c98;
      }
    }
    & > span {
      font-size: 12px;
    }
  }
  & > .right {
    width: 80px;
    text-align: right;
    @include mq(600px, max) {
      width: auto;
    }
    & > span {
      font-size: 18px;
      font-weight: 500;
      color: $purple;
    }
  }
  &:hover {
    background: rgba(233, 248, 253, 0.6);
    border-radius: 4px;
  }
}
.rtl{
  .my-progress-item{
    & > .left {
      margin-right: auto;
      & > .MuiLinearProgress-root{ 
        &.bg-orange > .MuiLinearProgress-bar{
              direction: rtl;        }
      }
    }
    & > .right {
      flex-direction: row-reverse;
      text-align: left;
    }
  }
 }
