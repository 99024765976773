.modalHeader {
  display: flex;
  justify-content: space-between;
}

.modalTitle > * {
  color: $blue;
  @include mq(560px, max) {
    //font-size: 14px !important;
  }
  @media screen and (max-width:360px){
    font-size: 18px !important;
 
   }
}

.modalClose * {
  font-weight: bold !important;
  color: $blue;
  @include mq(560px, max) {
  font-size: 12px !important;
  }

  cursor: pointer;
}
.closeTitle {
  justify-content: center;
  font-family: Tajawal;
  font-size: 12px;
  opacity: 1;
  &:hover {
    text-shadow: 0px 0px 0.5px $grey;
  }
}

.modalButton {
  background-color: $blue;
  color: white !important;
  font-weight: bold !important;
  text-transform: none !important;
  height: 30px;
  &:hover {
    filter: saturate(70%);
  }
}
