.latest-courses-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  & > :nth-child(n):not(:last-child) {
    margin-bottom: 12px;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 12px;
  }
}
