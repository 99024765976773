.subs-item-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // border-bottom: 1px solid $white2;
  margin-top: 8px !important;
  & > .label {
    color: $blue;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
  & > .MuiLinearProgress-root {
    height: 6px;
    border-radius: 6px;
    margin: 4px 0;
  }
  & > .due-date {
    color: $grey;
    text-align: center;
    font-size: 11px;
  }
}
