.hessaty-description {
  font-size: 17px;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  padding: 3px;
  .description-text {
    font-weight: bold;
    margin-right: 5px;
    margin-left: 10px;
  }
  .depassed-event {
    color: #ed5454;
  }
}
.hessaty-event-details {
  display: flex;
  justify-content: space-between;
  margin: 3px 0px;
  .hessaty-price {
    margin: 6px;
    font-weight: bold;
    .price {
      border: 1px solid $orange;
      color: $orange;
      padding: 3px 3px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        color: white;
        background-color: $orange;
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}

.hessaty-sessions {
  display: flex;
  justify-content: space-between;
}
