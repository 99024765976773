.show-more-link {
  width: 100%;
  text-align: center;
  color: #8bb4c6;
  font-size: 15px;
  font-weight: 500;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    color: $blue_dark;
  }
}
