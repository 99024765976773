.bank-accounts-list {
  @include mq(1280px, max) {
    display: flex;
    flex-direction: row;
    overflow: auto;
    position: absolute;
    width: 100%;
  }
}

.ta-responsive {
  width: auto;
  position: relative;
  @include mq(1280px, max) {
    height: 85px;
  }
}
