.rating-card-title {
  display: flex;
  font-size: 17px;
  font-family: Tajawal !important;
  font-weight: bold;
}

.rating-text {
  display: flex;
  font-size: 18px;
  font-family: Tajawal !important;
  justify-content: flex-end;
}

.emoji {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 110px;
  margin: 0px 15px;
  > img {
    opacity: 0.6;
    width: auto;
    height: 45px;

    &:hover {
      opacity: 1;
      width: auto;
      height: 55px;
    }
  }
  .selected-emoji {
    opacity: 1;
    width: auto;
    height: 57px;
  }
  @media screen and (max-width: 425px) {
    > img {
      width: 45px;
      height: 45px;
    }
  }
}

.session-informations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 0px 15px 0px;
  .session-item {
    display: flex;
    margin:10px 0px;
    .session-info-title {
      font-weight: bold;
      font-size: 16px;
      margin: 0px 3px;
    }
  }
}
.rating-session-text{
  display: flex;
  justify-content: center;
  font-family: Tajawal!important;
  font-weight: bold;
  font-size: 20px;
}
.add-comment-button {
  background-color: $blue;
  color: white;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Tajawal!important;
  font-weight: bold;
  height: 30px;
  border-radius: 5px;
  padding-top: 5px;
  cursor: pointer;
}
.comment {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  .comment-text-area {
    min-height: 100px;
    width: 70%;
  }
}