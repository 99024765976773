.content-table {
  width: 100%;
  max-height: 365px;
  background-color: transparent;
  position: relative;
  overflow: auto;
  border-style: none;
  @include mq(1380px, min) {
    max-height: 400px;
  }
  .listSection {
    background-color: red;
  }
  .li-section > a:last-child > .liStyle::after {
    content: none;
  }
  .ul {
    background-color: transparent;
    padding: 0px;
    margin-left: 0px;
  }
  .liStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
    cursor: pointer;
    transition: all 300ms ease;
    @include mq(560px, max) {
      padding: 8px 0;
    }
    &:hover > .li-texts {
      color: #2ba7df !important;
    }
    &::after {
      content: '';
      width: 91%;
      height: 1px;
      background: #2186b4;
      position: absolute;
      right: 0;
      margin-top: 28px;
    }
    & > .li-icon {
      position: absolute;
      width: 25px;
      height: 25px;
    }
    & > .li-texts {
      margin-left: 26px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      //color: #ffffff;
      padding-right: 12px;
      & > .li-completed {
        //color: #5d96af;
        color: #a0bdcc;
      }
    }
  }
  .header {
    color: #ffffff;
    font-family: tajawal;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    line-height: 13px;
    padding: 8px 0;
  }
  .play {
    color: #2ba7df;
    height: 25px !important;
  }
  .iconStyle {
    color: white;
    height: 25px !important;
  }
  .checkIconStyle {
    padding-right: 5px;
  }
  .content-title-completed {
    color: #ffffff;
    opacity: 0.4;
    text-align: left;
    font-family: tajawal;
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    @include mq(1380px, max) {
      font-size: 12px;
    }
  }
  .content-title {
    color: #ffffff;
    opacity: 1;
    text-align: left;
    font-family: tajawal;
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
    @include mq(1380px, max) {
      font-size: 12px;
    }
  }
  .lessonLink {
    opacity: 1;
  }
  .default {
    background: transparent;
  }
  & > .li-section {
    border-bottom: 1px solid white;
    margin-bottom: 8px;
    padding-bottom: 2px;
  }
}
