.wallet-balance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > .label {
    font-size: 16px;
    font-weight: 500;
    color: $darker_blue;
    &.muted {
      color: #a2a2a7;
    }
    & > .points {
      color: #ec5542;
    }
  }
  & > .MuiDivider-root {
    width: 80%;
    height: 2px;
    margin: 8px 0;
    background: $white2;
  }
  & > .points {
    font-size: 28px;
    font-weight: 600;
    color: $orange;
  }
  & > img {
    margin-top: 12px;
    width: 148px;
    &.mini-img {
      width: 143px;
    }
    @include mq(1380px, max) {
      display: none;
    }
  }
}

.rtl{
  .wallet-balance {
    & > .label{
      direction: rtl;
    }
    & > .points {
      direction: rtl;
    }
  }
}